<template>
  <div>
    <div>
      <div
        class="select-header"
        style="justify-content: flex-end; margin-bottom: 10px"
      >
        <!-- <div class="toPage">
          <img src="../../assets/images/toPage.png" alt="" />
          <span @click="$router.push('./import')">题库导入</span>
        </div>
        <div class="toPage">
          <img src="../../assets/images/toPage.png" alt="" />
          <span @click="$router.push('./interim')">临时题库</span>
        </div> -->
      </div>
      <div class="select-header">
        <div>
          <span style="margin-left: 15px; width: 44px">公司：</span>
          <el-select
            v-model="Company"
            clearable
            placeholder="请选择"
            @change="togetArea"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span style="margin-left: 15px; width: 44px"> 部门：</span>
          <el-select
            v-model="Dept"
            clearable
            placeholder="请选择"
            @change="GetDeptQuestions"
          >
            <el-option
              v-for="item in optionDept"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span style="margin-left: 15px; width: 74px">文件名称：</span>
          <el-input
            style="width: 23%"
            clearable
            @clear="curIndex = []"
            placeholder="请输入文件名称"
            v-model="fileName2"
          />
          <span style="margin-left: 10px"></span>
          <el-button icon="el-icon-search" @click="toSearch">搜索</el-button>
        </div>
        <div>
          <el-tooltip
            class="item"
            effect="dark"
            content="导入文件格式为xlsx"
            placement="top"
          >
            <el-button class="_ImgBtn" type="primary" @click="upExalloadFile">
              <img
                src="../../assets/images/import2.png"
                style="margin-right: 5px"
              />
              导入题库
            </el-button>
          </el-tooltip>
          <input
            type="file"
            @change="importFile(this)"
            id="imFile"
            ref="fileBtn"
            style="display: none"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <el-button
            class="_ImgBtn"
            type="primary"
            style="margin-left: 10px"
            @click="exportExcel"
          >
            <img
              src="../../assets/images/import1.png"
              style="margin-right: 5px"
            />
            导出
          </el-button>
          <el-button class="_ImgBtn" type="primary" @click="addQuestion">
            <img src="../../assets/images/add2.png" />
            添加题库</el-button
          >
          <el-button
            class="_ImgBtn"
            icon="el-icon-delete"
            @click="deleteItem"
          ></el-button>
        </div>
      </div>

      <div class="_Table">
        <el-table
          :data="tableData3"
          style="width: 100%; margin-top: 20px"
          :height="elementHeight"
          v-if="firstTable"
          ref="tableData3"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-click="rowClick"
          :cell-style="cellStyle"
          id="tableData3"
          v-loading="loading"
        >
          <el-table-column prop="compangyName" width="300" label="公司名称">
          </el-table-column>
          <el-table-column prop="fileCount" width="150" label="文件数量">
          </el-table-column>
          <el-table-column prop="questionCount" width="120" label="检查数量">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.questionCount }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column></el-table-column>
        </el-table>
        <el-table
          :data="tableData2"
          style="width: 100%; margin-top: 20px"
          :height="elementHeight"
          v-else
          ref="tableData"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-click="rowClick"
          :cell-style="cellStyle"
          @row-dblclick="cellDbclick"
          id="tableData2"
          v-loading="loading"
          class="tableBox"
        >
          <el-table-column prop="fileName" width="300" label="文件名称"> </el-table-column>
          <el-table-column prop="fileNo" width="150" label="文件编号">
          </el-table-column>
          <el-table-column
            prop="fileQuestionsCount"
            @click="dialogVisibleSearch = true"
            width="120"
            label="检查数量"
          >
            <template slot-scope="scope">
              <div @click="toOpen2(scope.row.fileNo)">
                <span style="color: blue">{{
                  scope.row.fileQuestionsCount
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column></el-table-column>
        </el-table>

        <div class="total">
          文件总数： <span>{{ fileTotal }}</span> 问题总数：
          <span>{{ total }}</span>
        </div>
      </div>
    </div>
    <Dialog
      title="添加题库"
      :showTowText="showBtnText"
      width="50%"
      top="15vh"
      :showDialog="showDialog"
      class="dialog"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
    >
      <div class="contaion">
        <div>
          <el-form ref="form" label-width="100px">
            <el-form-item label="公司：">
              <el-select
                v-model="dialogCompy"
                placeholder="请选择公司"
                @change="changDialogCompy"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.companyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="部门：">
              <el-select
                v-model="dialogDept"
                placeholder="请选择部门"
                @change="changDialogDept"
              >
                <el-option
                  v-for="item in dialogDeptList"
                  :key="item.value"
                  :label="item.deptmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="岗位：">
              <el-select
                v-model="dialogDept"
                placeholder="请选择岗位"
                @change="changDialogDept"
              >
                <el-option
                  v-for="item in dialogDeptList"
                  :key="item.value"
                  :label="item.deptmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="文件编号：">
              <el-input placeholder="请输入文件编号" v-model="fileNo">
              </el-input>
            </el-form-item>
            <el-form-item label="文件名称：">
              <el-input placeholder="请输入文件名称" v-model="fileName">
              </el-input>
            </el-form-item>
            <el-form-item label="问题描述：">
              <el-input placeholder="请输入问题描述" v-model="question">
              </el-input>
            </el-form-item>
            <el-form-item label="版本号：">
              <el-input placeholder="请输入版本号" v-model="version">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form ref="form" label-width="120px">
            <el-form-item label="涉及范畴：">
              <el-select
                v-model="auditTypeName"
                collapse-tags
                multiple
                placeholder="请选择涉及范畴"
              >
                <el-option
                  v-for="item in auditTypeList"
                  :key="item.value"
                  :label="item.auditTypeName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="涉及职能：">
              <el-select
                v-model="funName"
                collapse-tags
                multiple
                placeholder="请选择涉及职能"
              >
                <el-option
                  v-for="item in option3"
                  :key="item.value"
                  :label="item.functionName"
                  :value="item.functionId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标准答案：">
              <el-input placeholder="请输入标准答案" v-model="answer">
              </el-input>
            </el-form-item>
            <el-form-item label="标准要求：">
              <el-select
                v-model="standardAbbr"
                multiple
                collapse-tags
                placeholder="请选择标准要求"
              >
                <el-option
                  v-for="item in standardList"
                  :key="item.value"
                  :label="item.standardAbbr"
                  :value="item.standardAbbr"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="法律法规要求：">
              <el-input placeholder="请输入法律法规要求" v-model="lawAsk">
              </el-input>
            </el-form-item>
            <el-form-item label="风险描述：">
              <el-input placeholder="请输入风险描述" v-model="question1">
              </el-input>
            </el-form-item>
            <el-form-item label="涉及区域：">
              <el-select
                v-model="areaName"
                collapse-tags
                multiple
                placeholder="请选择涉及区域"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.value"
                  :label="item.areaName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div style="padding-left: 38px; padding-top: 15px">
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :http-request="uploadFile"
            :headers="token"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
    </Dialog>
    <el-dialog
      title="题库统计"
      :visible.sync="dialogVisibleAsk"
      width="60%"
      top="5vh"
    >
      <span style="margin-left: 15px">公司：</span>
      <el-select
        v-model="Company"
        clearable
        placeholder="请选择"
        @change="togetArea"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.companyName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span style="margin-left: 15px"> 部门：</span>
      <el-select
        v-model="Dept"
        clearable
        placeholder="请选择"
        @change="GetDeptQuestions"
      >
        <el-option
          v-for="item in optionDept"
          :key="item.value"
          :label="item.deptmentName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span style="margin-left: 15px">文件名称：</span>
      <el-input
        style="width: 23%"
        clearable
        placeholder="请输入文件名称"
        v-model="fileName2"
      />
      <span style="margin-left: 10px"></span>
      <el-button type="primary" @click="toSearch">搜索</el-button>
      <el-table
        :data="tableData2"
        style="width: 100%; margin-top: 20px"
        height="400"
        ref="tableData"
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
        :cell-style="cellStyle"
        id="tableData2"
      >
        <el-table-column prop="fileName" label="文件名称" width="580">
        </el-table-column>
        <el-table-column prop="fileNo" label="文件编号" width="280">
        </el-table-column>
        <el-table-column
          prop="fileQuestionsCount"
          @click="dialogVisibleSearch = true"
          label="检查数量"
        >
          <template slot-scope="scope">
            <div @click="toOpen2(scope.row.fileNo)">
              <span>{{ scope.row.fileQuestionsCount }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="total">
        问题总数： <span>{{ total }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleAsk = false"
          >确 定</el-button
        >
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="题库" fullscreen :visible.sync="dialogVisibleSearch">
      <div class="qustionBox">
        <el-button
          type="primary"
          icon="el-icon-arrow-left"
          @click="dialogVisibleSearch = false"
          >返回</el-button
        >
        <el-button
          class="_ImgBtn"
          @click="exportExcel2"
          style="margin-top: 0; margin-bottom: 5px"
          type="primary"
        >
          <img
            src="../../assets/images/import1.png"
            style="margin-right: 5px"
          />
          导出</el-button
        >
        <el-button
          class="_ImgBtn"
          type="primary"
          style="margin-left: 10px"
          @click="showExportHeader = true"
        >
          <img
            src="../../assets/images/import1.png"
            style="margin-right: 5px"
          />
          导出表头
        </el-button>
        <span>
          <img
            style="cursor: pointer; width: 30px; height: 30px; margin-left: 5px"
            @click="delItem"
            src="../../assets/images/Examine_img/del.png"
            alt=""
          />
        </span>
      </div>
      <el-table
        :data="questionList2"
        :height="elementHeight + 100"
        style="width: 100%"
        @cell-dblclick="toChange"
        v-loading="loading"
        @select="selectRow"
        :cell-class-name="tableCellClassName"
        border
        id="tabledData3"
      >
        <el-table-column type="selection" width="80" v-if="isUse">
        </el-table-column>
        <el-table-column prop="compName" label="公司" v-if="!isUse" width="180">
        </el-table-column>
        <el-table-column prop="deptName" label="部门"> </el-table-column>
        <el-table-column prop="postName" label="岗位"> </el-table-column>
        <el-table-column prop="functionName" label="职能"> </el-table-column>
        <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
        <el-table-column prop="fileName" label="文件名称"> </el-table-column>
        <el-table-column prop="question" width="380" label="检查内容">
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.question }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="answer" width="380" label="标准答案">
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.answer }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="standardAbbr" label="标准要求">
        </el-table-column>
        <el-table-column prop="riskPoints" label="风险点"> </el-table-column>
        <el-table-column prop="riskMeasures" label="风险应对措施">
        </el-table-column>
        <el-table-column prop="version" label="版本号" width="40">
        </el-table-column>
        <el-table-column prop="" label="检查类别">
          <el-table-column prop="" label="1">
            <el-table-column prop="areaAudit" label="现场检查">
              <template slot="header">
                现场检查
                <input
                  type="checkbox"
                  ref="checkedareaAudit"
                  v-model="checkedareaAudit"
                  @change="changeCheck('areaAudit')"
                />
              </template>
              <template slot-scope="scope">
                <div>
                  {{ scope.row.areaAudit ? "√" : "" }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="fileAudit" label="文件检查" align="center">
              <template slot="header">
                文件检查
                <input
                  type="checkbox"
                  ref="checkedfileAudit"
                  v-model="checkedfileAudit"
                  @change="changeCheck('fileAudit')"
                />
              </template>
              <template slot-scope="scope">
                <div>
                  {{ scope.row.fileAudit ? "√" : "" }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="isCurrency" label="通用">
          <template slot="header">
            通用
            <input
              type="checkbox"
              ref="checkedisCurrency"
              v-model="checkedisCurrency"
              @change="changeCheck('isCurrency')"
            />
          </template>
          <template slot-scope="scope">
            <div>
              {{ scope.row.isCurrency ? "√" : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isFuncCurrency" label="职能通用">
          <template slot="header">
            职能通用
            <input
              type="checkbox"
              ref="checkedisFuncCurrency"
              v-model="checkedisFuncCurrency"
              @change="changeCheck('isFuncCurrency')"
            />
          </template>
          <template slot-scope="scope">
            <div>
              {{ scope.row.isFuncCurrency ? "√" : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isSpecial" label="专指">
          <template slot="header">
            专指
            <input
              type="checkbox"
              ref="checkedisSpecial"
              v-model="checkedisSpecial"
              @change="changeCheck('isSpecial')"
            />
          </template>
          <template slot-scope="scope">
            <div>
              {{ scope.row.isSpecial ? "√" : "" }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="" align="center" label="所在区域">
          <el-table-column
            prop="arrArea"
            :label="index + 1 + ''"
            v-for="(item, index) in areaList"
            :key="index"
          >
            <el-table-column :label="item.areaName">
              <template slot="header">
                {{ item.areaName }}
                <input
                  type="checkbox"
                  class="checkbox"
                  :v-model="'checked' + index"
                  @change="changeArea($event, item.areaName, 'checked' + index)"
                />
              </template>
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes(item.areaName)
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>

        <el-table-column prop="" align="center" label="检查范畴">
          <el-table-column
            prop=""
            :label="index + 1 + ''"
            v-for="(item, index) in auditTypeList"
            :key="index"
          >
            <el-table-column prop="" :label="item.auditTypeName">
              <template slot="header">
                {{ item.auditTypeName }}
                <input
                  type="checkbox"
                  class="checkbox1"
                  :v-model="'checked' + index"
                  @change="
                    changeauditType(
                      $event,
                      item.auditTypeName,
                      'checked' + index
                    )
                  "
                />
              </template>
              <template slot-scope="scope">
                <div>
                  <div>
                    {{
                      scope.row.arrAuditType
                        .split(",")
                        .includes(item.auditTypeName)
                        ? "√"
                        : ""
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleSearch = false">取 消</el-button>
        <el-button type="primary" @click="toSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showExportHeader" title="导出表头" width="80%">
      <el-table
        :data="questionList3"
        height="200"
        style="width: 100%"
        id="tabledData4"
      >
        <!-- <el-table-column type="selection" width="80"> </el-table-column> -->
        <el-table-column prop="compName" label="公司" width="180">
        </el-table-column>
        <el-table-column prop="deptName" label="部门"> </el-table-column>
        <el-table-column prop="postName" label="岗位"> </el-table-column>
        <el-table-column prop="functionName" label="职能"> </el-table-column>
        <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
        <el-table-column prop="fileName" label="文件名称"> </el-table-column>
        <el-table-column
          prop="interpretation"
          label="应知应会"
        ></el-table-column>
        <el-table-column prop="question" label="检查内容"> </el-table-column>
        <el-table-column prop="answer" width="380" label="标准答案">
        </el-table-column>
        <el-table-column prop="standardAbbr" label="标准要求">
        </el-table-column>
        <el-table-column prop="riskPoints" label="风险点"> </el-table-column>
        <el-table-column prop="riskMeasures" label="风险应对措施">
        </el-table-column>
        <el-table-column prop="version" label="版本号" width="40">
        </el-table-column>
        <el-table-column prop="" align="center" label="检查类别">
          <el-table-column prop="" label="1">
            <el-table-column prop="areaAudit" label="现场检查">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.areaAudit ? "√" : "" }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="fileAudit" label="文件检查">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.fileAudit ? "√" : "" }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="isCurrency" label="通用">
          <template slot-scope="scope">
            <div>
              {{ scope.row.isCurrency ? "√" : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isFuncCurrency" label="职能通用">
          <template slot-scope="scope">
            <div>
              {{ scope.row.isFuncCurrency ? "√" : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isSpecial" label="专指">
          <template slot-scope="scope">
            <div>
              {{ scope.row.isSpecial ? "√" : "" }}
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="所在区域">
          <el-table-column
            prop="arrArea"
            :label="index + 1 + ''"
            v-for="(item, index) in areaList"
            :key="index"
          >
            <el-table-column :label="item.areaName">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes(item.areaName)
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <!-- <el-table-column prop="arrArea" label="1">
            <el-table-column label="厂区区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes("厂区区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="arrArea" label="车间区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes("车间区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="3">
            <el-table-column prop="" label="仓库区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes("仓库区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="4">
            <el-table-column prop="" label="实验室区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes("实验室区域")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="5">
            <el-table-column prop="" label="办公区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes("办公区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="6">
            <el-table-column prop="" label="饭堂区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes("饭堂区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column> 
          </el-table-column>
          <el-table-column prop="" label="7">
            <el-table-column prop="" label="宿舍区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes("宿舍区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="8">
            <el-table-column prop="" label="工程区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes("工程区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column> -->
        </el-table-column>
        <el-table-column align="center" label="检查范畴">
          <el-table-column
            prop="arrAuditType"
            :label="index + 1 + ''"
            v-for="(item, index) in auditTypeList"
            :key="index"
          >
            <el-table-column :label="item.auditTypeName">
              <template slot-scope="scope">
                <div>
                  <div>
                    {{
                      scope.row.arrAuditType
                        .split(",")
                        .includes(item.auditTypeName)
                        ? "√"
                        : ""
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>

          <!-- <el-table-column prop="" label="1">
            <el-table-column prop="" label="安全生产">
              <template slot-scope="scope">
                <div>
                  <div>
                    {{
                      scope.row.arrAuditType.split(",").includes("安全生产")
                        ? "√"
                        : ""
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="" label="领导干部现场带班">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("领导干部现场带班")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="3">
            <el-table-column prop="" label="三违行为管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("三违行为管理")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="4">
            <el-table-column prop="" label="班组岗位达标安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("班组岗位达标安全")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="5">
            <el-table-column prop="" label="安全教育作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("安全教育作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="6">
            <el-table-column prop="" label="交叉作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("交叉作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="7">
            <el-table-column prop="" label="相关方施工安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("相关方施工安全")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="8">
            <el-table-column prop="" label="特种作业人员管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("特种作业人员管理")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="9">
            <el-table-column prop="" label="特种设备管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("特种设备管理")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="10">
            <el-table-column prop="" label="危险作业工作票作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("危险作业工作票作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="11">
            <el-table-column prop="" label="动火作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("动火作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="12">
            <el-table-column prop="" label="高空作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("高空作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="13">
            <el-table-column prop="" label="吊装作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("吊装作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="14">
            <el-table-column prop="" label="有限空间作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("有限空间作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="15">
            <el-table-column prop="" label="搬运安全作业"
              ><template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("搬运安全作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="16">
            <el-table-column prop="" label="电焊机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("电焊机安全作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="17">
            <el-table-column prop="" label="气焊机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("气焊机安全作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="18">
            <el-table-column prop="" label="砂轮机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("砂轮机安全作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="19">
            <el-table-column prop="" label="手持电动工具安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("手持电动工具安全作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="20">
            <el-table-column prop="" label="起重机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("起重机安全作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="21">
            <el-table-column prop="" label="液氨泄漏探测仪检测作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("液氨泄漏探测仪检测作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="22">
            <el-table-column prop="" label="液氨使用作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("液氨使用作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="23">
            <el-table-column prop="" label="涉水作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("涉水作业")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="24">
            <el-table-column prop="" label="危险化学品">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("危险化学品")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="25">
            <el-table-column prop="" label="一般化学品">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("一般化学品")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="26">
            <el-table-column prop="" label="安全用电">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("安全用电")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="27">
            <el-table-column prop="" label="临时用电">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("临时用电")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="28">
            <el-table-column prop="" label="职业健康安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("职业健康安全")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="29">
            <el-table-column prop="" label="劳动防护">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("劳动防护")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="30">
            <el-table-column prop="" label="施工安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("施工安全")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="31">
            <el-table-column prop="" label="消防设施">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("消防设施")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="32">
            <el-table-column prop="" label="消防设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("消防设备")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="33">
            <el-table-column prop="" label="疏散指示">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("疏散指示")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="34">
            <el-table-column prop="" label="特种设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("特种设备")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="35">
            <el-table-column prop="" label="机械设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("机械设备")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="36">
            <el-table-column prop="" label="投加设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("投加设备")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="37">
            <el-table-column prop="" label="高低压电柜">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("高低压电柜")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="38">
            <el-table-column prop="" label="一般设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("一般设备")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="39">
            <el-table-column prop="" label="作业环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("作业环境")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="40">
            <el-table-column prop="" label="办公环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("办公环境")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="41">
            <el-table-column prop="" label="实验室环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("实验室环境")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="42">
            <el-table-column prop="" label="车间环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("车间环境")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="43">
            <el-table-column prop="" label="仓库环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("仓库环境")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="44">
            <el-table-column prop="" label="宿舍环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("宿舍环境")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="45">
            <el-table-column prop="" label="应急物资">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("应急物资")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="46">
            <el-table-column prop="" label="应急设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("应急设备")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="47">
            <el-table-column prop="" label="应急预案">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("应急预案")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="48">
            <el-table-column prop="" label="应急演练">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("应急演练")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="49">
            <el-table-column prop="" label="7S管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("7S管理")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="50">
            <el-table-column prop="" label="设备状态">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("设备状态")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="51">
            <el-table-column prop="" label="安全警示标识">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("安全警示标识")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="52">
            <el-table-column prop="" label="作业指导书标识">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("作业指导书标识")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="53">
            <el-table-column prop="" label="行为标识">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("行为标识")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="54">
            <el-table-column prop="" label="信息栏">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("信息栏")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="55">
            <el-table-column prop="" label="卫生用品存放">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("卫生用品存放")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="56">
            <el-table-column prop="" label="四色图">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("四色图")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="57">
            <el-table-column prop="" label="疫情防控">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("疫情防控")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="58">
            <el-table-column prop="" label="管道安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("管道安装工程质量")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="59">
            <el-table-column prop="" label="疫情防控">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("疫情防控")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="60">
            <el-table-column prop="" label="构筑物工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("构筑物工程质量")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="61">
            <el-table-column prop="" label="道路工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType.split(",").includes("道路工程质量")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="62">
            <el-table-column prop="" label="设备安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("设备安装工程质量")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="63">
            <el-table-column prop="" label="机电安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("机电安装工程质量")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="64">
            <el-table-column prop="" label="水环境治理工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrAuditType
                      .split(",")
                      .includes("水环境治理工程质量")
                      ? "√"
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column> -->
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExportHeader = false">取消</el-button>
        <el-button type="primary" @click="exportHeaderExcel"
          >确定导出</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, baseURL } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
import XLSX from "xlsx";
// import FileSaver from "file-saver";
import { exportExcel } from "../../modules/ExportExcel";
import { compressImage } from "../../modules/CompressImage";
import imgTypeChange from "../../modules/imgChange";
// import { saveAs } from "file-saver";

export default {
  components: { Dialog },
  data() {
    return {
      BUrl: baseURL[process.env.NODE_ENV + ""],
      token: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      isUse: true,
      elementHeight: 0,
      showDialog: false,
      fileNameInp: "",
      tableData2: [],
      tableData3: [],
      showBtnText: false,
      rowItem: {},
      options: [],
      Company: "",
      delQuestion: "",
      dialogVisibleAsk: false,
      tableRowIndex: null,
      importDataList: [],
      question1: "",
      dialogDeptList: [],
      reqImageList: [],
      // 搜索
      fileNoInp: "",
      questionList: [],
      isSearch: false,
      // 参数
      dialogCompy: "",
      dialogDept: "",
      fileNo: "",
      fileName: "",
      question: "",
      answer: "",
      standardAbbr: "",
      lawAsk: "",
      version: "",
      // 分页
      currentPage: 1,
      pageSize: 20,
      listCount: 0,
      //
      auditTypeName: "",
      areaName: "",
      funName: "",
      option1: [],
      option2: [],
      option3: [],
      //问题统计
      total: 0,
      fileName2: "",
      optionDept: "",
      Dept: "",
      loading: false,
      curIndex: [],
      dialogVisibleSearch: false,
      questionList2: null,
      fileTotal: 0,
      //
      postList: [],
      True: true,
      firstTable: true,
      showExportHeader: false,
      questionList3: [],
      standardList: [],
      areaList: [],
      auditTypeList: [],
      checkedareaAudit: false,
      checkedfileAudit: false,
      checkedisCurrency: false,
      checkedisFuncCurrency: false,
      checkedisSpecial: false,
    };
  },
  mounted() {
    this.getdate();
    this.imFile = document.getElementById("imFile");
    this.getElementHeight();
    this.getStandard();
    this.options = this.$store.state.companyList;
    this.Company = this.$store.state.userInfo.upCompanyId;
    this.getDeptTree();
    this.getCompnyFile();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("tableData3").offsetTop + 134);
      });
    },
    // changeCheck(val) {
    //   this.questionList2.forEach(item => {
    //     for (let key in item) {
    //       if(val == key){
    //         if(this.checked){
    //           item[key] = 1;
    //         }else{
    //           item[key] = 0;
    //           }
    //         }
    //       }
    //   });
    // },
    changeCheck(val) {
      this.questionList2.forEach((item) => {
        if (val in item) {
          item[val] = this["checked" + val] ? 1 : 0;
        }
      });
      this.postList = this.questionList2;
    },
    changeArea(e, val, i) {
      i = e.target.checked;
      if (i) {
        this.questionList2.forEach((item) => {
          item.arrArea.split(",").forEach((value) => {
            if (value !== val && item.arrArea) {
              item.arrArea += "," + val;
            } else if (!item.arrArea) {
              item.arrArea = val;
            }
          });
        });
      } else {
        this.questionList2.forEach((item) => {
          item.arrArea = item.arrArea
            .split(",")
            .filter((value) => value !== val)
            .join(",");
        });
      }
      this.postList = this.questionList2;
    },
    changeauditType(e, val, i) {
      i = e.target.checked;
      if (i) {
        this.questionList2.forEach((item) => {
          item.arrAuditType.split(",").forEach((value) => {
            if (value !== val && item.arrAuditType) {
              item.arrAuditType += "," + val;
            } else if (!item.arrAuditType) {
              item.arrAuditType = val;
            }
          });
        });
      } else {
        this.questionList2.forEach((item) => {
          item.arrAuditType = item.arrAuditType
            .split(",")
            .filter((value) => value !== val)
            .join(",");
        });
      }
      this.postList = this.questionList2;
    },
    getStandard() {
      get("/api/StandardInfo/GetList").then((resp) => {
        if (resp.code == 200) {
          this.standardList = resp.data;
        }
      });
    },
    getDeptTree() {
      get("/api/Department/GetNoTree?CompId=" + this.Company).then((res) => {
        this.optionDept = res.data;
      });
    },
    getCompnyFile() {
      get("/api/QuestionAndCompangy").then((res) => {
        if (res.code == 200) {
          if (res.data.length != 0) {
            res.data.map((item) => {
              this.total += item.questionCount;
              this.fileTotal += item.fileCount;
            });
          }
          this.tableData3 = res.data;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getQuestionList();
    },
    rowClick(row) {
      this.rowItem = row;
      this.tableRowIndex = row.index;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    fuzzyQuery(list, keyWord) {
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        if (list[i].fileName.indexOf(keyWord) >= 0) {
          arr.push(list[i].index);
        }
      }
      return arr;
    },
    toSearch() {
      get(
        "/api/QuestionPool/GetQuestionByFile?CompId=" +
          this.Company +
          "&DeptId=" +
          this.Dept +
          "&FileNo=" +
          "&FileName=" +
          this.fileName2
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.total = 0;
            resp.data.map((item) => {
              this.total += item.fileQuestionsCount;
            });
            this.tableData2 = resp.data;
            this.firstTable = false;
            this.tableRowIndex = null;
            this.fileTotal = this.tableData2.length;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("搜索失败");
        });
    },
    getdate() {
      get("/api/AuditType/GetList").then((res) => {
        this.option1 = res.data;
        this.auditTypeList = res.data.filter((item) => item.upId !== 0);
      });
      get("/api/Area/GetList").then((res) => {
        this.option2 = res.data;
        this.areaList = res.data.filter((item, index, self) => {
          return (
            index === self.findIndex((obj) => obj.areaName === item.areaName)
          );
        });
      });
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    cellStyle(e) {
      if (this.curIndex.includes(e.row.index)) {
        return "background: #409eff; color:#ffffff";
      }
      // return "background: #409eff; color:#ffffff";
    },
    handleClose() {
      this.showDialog = false;
    },
    // 上传图片
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file);
        let newfile = await compressImage(file);
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile);
        }
        this.uploadFileReq(newfile, e.file.uid);
      }
    },
    handleRemove(file) {
      this.reqImageList.map((i, index) => {
        if (file.raw.uid == i.uid) {
          this.reqImageList.splice(index, 1);
        }
      });
    },
    uploadFileReq(file, uid) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadImg", formData)
        .then((res) => {
          if (res.code == 200) {
            this.reqImageList.push({ uid: uid, value: res.value });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$message.error("上传失败");
        });
    },
    // 增删改
    handleReqData() {
      if (!this.dialogCompy || !this.dialogDept) {
        this.$message({
          type: "warning",
          message: "请选择公司与部门",
        });
        return;
      }
      var imageAddr = [];
      if (this.reqImageList.length != 0) {
        this.reqImageList.map((i) => {
          imageAddr.push(i.value);
        });
      }
      var data = {
        id: 0,
        compId: this.dialogCompy,
        deptId: this.dialogDept,
        fileNo: this.fileNo,
        fileName: this.fileName,
        question: this.question,
        answer: this.answer,
        imageAddr: imageAddr.toString(),
        lawAsk: this.lawAsk,
        version: this.version,
        standard: this.standardAbbr.toString(),
        isDelete: 0,
        questionAndArea: this.areaName.toString(),
        questionAndFunction: this.funName.toString(),
        questionAndAuditType: this.auditTypeName.toString(),
        saveTime: new Date().toISOString(),
        saveUserId: this.$store.state.userInfo.id + "",
      };
      post("/api/NewQuestionPool", data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.togetArea(this.Company);
            this.showDialog = false;
            this.isSearch = false;
            this.reqImageList = [];
            this.$refs.upload.clearFiles();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("添加失败");
        });
    },
    toOpen() {
      this.dialogVisibleAsk = true;
      get("/api/Company/GetAll").then((res) => {
        this.options = res.data;
      });
    },
    cellDbclick(row) {
      this.toOpen2(row.fileNo);
    },
    toOpen2(v) {
      if (!this.Company) {
        this.$message({
          type: "warning",
          message: "请选择公司部门",
        });
        return;
      }
      this.loading = true;
      this.questionList2 = [];
      get(
        "/api/QuestionPoolUpt/QuestionPoolExport?CompId=" +
          this.Company +
          "&DeptId=" +
          this.Dept +
          "&FileNo=" +
          v
      ).then((res) => {
        res.data.forEach((row) => {
          if (row.postName === "") {
            row.postName = "空";
          }
        });
        this.questionList2 = res.data;
        this.loading = false;
        this.dialogVisibleSearch = true;
        this.$nextTick(() => {
          let checkbox = document.getElementsByClassName("checkbox");
          let checkbox1 = document.getElementsByClassName("checkbox1");
          this.$refs.checkedareaAudit.checked = this.questionList2.every(
            (item) => item.areaAudit
          );
          this.$refs.checkedfileAudit.checked = this.questionList2.every(
            (item) => item.fileAudit
          );
          this.$refs.checkedisCurrency.checked = this.questionList2.every(
            (item) => item.isCurrency
          );
          this.$refs.checkedisFuncCurrency.checked = this.questionList2.every(
            (item) => item.isFuncCurrency
          );
          this.$refs.checkedisSpecial.checked = this.questionList2.every(
            (item) => item.isSpecial
          );
          this.areaList.forEach((v, i) => {
            if (
              this.questionList2.every((item) =>
                item.arrArea.split(",").includes(v.areaName)
              )
            ) {
              checkbox[i].checked = true;
            } else {
              checkbox[i].checked = false;
            }
          });
          this.auditTypeList.forEach((v, i) => {
            if (
              this.questionList2.every((item) =>
                item.arrAuditType.split(",").includes(v.auditTypeName)
              )
            ) {
              checkbox1[i].checked = true;
            } else {
              checkbox1[i].checked = false;
            }
          });
        });
      });
    },

    GetDeptQuestions() {
      if (!this.Dept) return;
      get(
        "/api/QuestionPool/GetDeptFileQuestions?CompId=" +
          this.Company +
          "&DeptId=" +
          this.Dept
      ).then((res) => {
        if (res.code == 200) {
          this.total = 0;
          this.fileTotal = 0;
          if (res.data.length != 0) {
            res.data.map((item) => {
              this.total += item.fileQuestionsCount;
            });
          }
          this.fileTotal = res.data.length;
          this.curIndex = [];
          this.tableData2 = res.data;
          this.firstTable = false;
          this.tableRowIndex = null;
        }
      });
    },

    togetArea(e) {
      if (!e) return;
      this.curIndex = [];
      this.Dept = "";
      this.optionDept = [];
      this.fileName2 = "";
      this.getDeptList(e);
      get("/api/QuestionPool/GetFileQuestions?CompId=" + e).then((res) => {
        if (res.code == 200) {
          this.total = 0;
          if (res.data.length != 0) {
            res.data.map((item) => {
              this.total += item.fileQuestionsCount;
            });
          }
          this.fileTotal = res.data.length;
          this.firstTable = false;
          this.tableData2 = res.data;
        }
      });
    },
    getDeptList(e) {
      get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
        if (this.showDialog) {
          this.dialogDeptList = res.data;
          return;
        }
        this.optionDept = res.data;
      });
    },
    changDialogCompy(e) {
      this.getDeptList(e);
      this.dialogDept = "";
      this.funName = [];
    },
    changDialogDept(e) {
      this.funName = [];
      this.option3 = [];
      get(
        "/api/PostAndFile/GetFunctionList?CompId=" +
          this.dialogCompy +
          "&DeptId=" +
          e
      ).then((res) => {
        this.option3 = res.data;
      });
    },
    addQuestion() {
      this.dialogCompy = "";
      this.dialogDeptList = [];
      this.dialogDept = "";
      this.option3 = [];
      this.showDialog = true;
      this.showBtnText = false;
      this.fileNo = "";
      this.fileName = "";
      this.question = "";
      this.answer = "";
      this.lawAsk = "";
      this.question1 = "";
      this.version = "";
      this.areaName = "";
      this.funName = "";
      this.auditTypeName = "";
      this.standardAbbr = "";
    },
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex;
    },
    toChange(item, column) {
      console.log(column.index);
      if (column.index >= 12 && column.index <= 17) {
        var n = column.property;
        item[n] === 1 ? (item[n] = 0) : (item[n] = 1);
      }
      if (column.index >= 18 && column.index <= 17 + this.areaList.length) {
        if (item.arrArea.split(",").indexOf(column.label) >= 0) {
          var Nitem = item.arrArea.split(",");
          var index = item.arrArea.split(",").indexOf(column.label);
          Nitem.splice(index, 1);
          item.arrArea = Nitem.toString();
        } else {
          item.arrArea === ""
            ? (item.arrArea = column.label)
            : (item.arrArea = item.arrArea + "," + column.label);
        }
      }
      if (column.index >= 18 + this.areaList.length) {
        if (item.arrAuditType.split(",").indexOf(column.label) >= 0) {
          var Nitem2 = item.arrAuditType.split(",");
          var index2 = item.arrAuditType.split(",").indexOf(column.label);
          Nitem2.splice(index2, 1);
          item.arrAuditType = Nitem2.toString();
        } else {
          item.arrAuditType === ""
            ? (item.arrAuditType = column.label)
            : (item.arrAuditType = item.arrAuditType + "," + column.label);
        }
      }
      JSON.stringify(this.postList).indexOf(JSON.stringify(item)) >= 0
        ? ""
        : this.postList.push(item);
    },
    toSure() {
      this.loading = true;
      post("/api/QuestionPoolUpt/BatchUpt", {
        data: JSON.stringify(this.postList),
      }).then((res) => {
        this.loading = false;
        this.postList = [];
        this.$message({
          type: "success",
          message: res.message,
        });
      });
    },
    changeRow(row) {
      post("/api/QuestionPoolUpt/BatchUpt", {
        data: JSON.stringify([row]),
      }).then(() => {
        this.loading = false;
      });
    },
    selectRow(e) {
      this.delQuestion = e;
    },
    deleteItem() {
      if (this.firstTable) {
        this.$message({
          type: "warning",
          message: "不可删除",
        });
        return;
      }
      if (this.tableRowIndex == null) return;
      this.$confirm("您确认要删除“" + this.rowItem.fileName + "”的这个文件？")
        .then(() => {
          deleteMethod("/api/NewQuestionPool/DeleteByFile", {
            FileNo: this.rowItem.fileNo,
            FileName: this.rowItem.fileName,
          })
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.tableData2.splice(this.tableRowIndex, 1);
                this.tableRowIndex = null;
                this.rowItem = {};
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    // 导入excel
    upExalloadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          // // 创建工作簿
          // const workbook = XLSX.utils.book_new();
          // // 创建工作表
          // const header = [
          //   "公司",
          //   "部门",
          //   "岗位",
          //   "职能",
          //   "文件编号",
          //   "文件名称",
          //   "问题描述",
          //   "标准答案",
          //   "法律法规要求",
          // ];
          // const worksheet = XLSX.utils.aoa_to_sheet([header]); // 使用aoa_to_sheet创建带有表头的工作表
          // // 将工作表添加到工作簿中
          // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          // // 将工作簿转换为二进制数据流
          // const data = XLSX.write(workbook, {
          //   bookType: "xlsx",
          //   type: "array",
          // });
          // // 创建一个Blob对象并设置数据流
          // const blob = new Blob([data], { type: "application/octet-stream" });
          // // 使用file-saver保存文件
          // saveAs(blob, "标准格式文件.xlsx");
          this.showExportHeader = true;
        })
        .catch((action) => {
          if (action === "cancel") {
            // 点击导入按钮
            this.imFile.click();
          }
        });
    },
    importFile: function () {
      var importDataList = [];

      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        console.log(excelData);
        // excelData = [excelData[2]];
        excelData = excelData.slice(2);
        excelData.map((item) => {
          const emptyFields = [];
          $this.areaList.forEach((item, index) => {
            emptyFields.push(`__EMPTY_${index + 1}`);
          });
          emptyFields.pop();
          console.log(emptyFields, "emptyFields");
          item.所在区域 = emptyFields.reduce((acc, field) => {
            if (item[field]) {
              acc += "," + item[field];
            }
            return acc;
          }, item.所在区域);

          const emptyCategory = Array.from(
            { length: $this.auditTypeList.length - 1 },
            (_, i) => `__EMPTY_${i + $this.areaList.length}`
          );
          console.log(emptyCategory, "emptyCategory");
          item.检查范畴 = emptyCategory.reduce((acc, field) => {
            if (item[field]) {
              acc += "," + item[field];
            }
            return acc;
          }, item.检查范畴);

          importDataList.push({
            id: 0,
            CompName: item.公司,
            DeptName: item.部门,
            PostName: item.岗位,
            FunctionName: item.职能,
            FileNo: item.文件编号,
            FileName: item.文件名称,
            Question: item.检查内容,
            Interpretation: item.应知应会,
            Answer: item.标准答案,
            StandardAbbr: item.标准要求,
            Version: item.版本号,
            Module: item.模块,
            RiskPoints: item.风险点,
            RiskMeasures: item.风险应对措施,
            IsCurrency: item.通用 ? "1" : "0",
            IsFuncCurrency: item.职能通用 ? "1" : "0",
            IsSpecial: item.专指 ? "1" : "0",
            AreaAudit: item.检查类别 ? "1" : "0", //现场检查
            FileAudit: item.__EMPTY ? "1" : "0", //文件检查
            arrArea: item.所在区域
              ? item.所在区域.replace(/^undefined,/, "")
              : "",
            arrAuditType: item.检查范畴
              ? item.检查范畴.replace(/^undefined,/, "")
              : "",
            // lawAsk: item.法律法规要求,
            // imageAddr: "",
            // isDelete: 0,
            // saveTime: new Date().toISOString(),
            // saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id + "",
          });
        });
        console.log(importDataList, 77);
        if (importDataList.length != 0) {
          $this.importQuestion(importDataList);
        } else {
          $this.$message({
            type: "warning",
            message: "导入文件不符合",
          });
          this.$refs.fileBtn.value = null;
        }
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    delItem() {
      if (this.delQuestion.length === 0) {
        this.$message({
          message: "请勾选需要删除的数据",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      const idList = this.delQuestion.map((element) => ({
        QuestionId: element.questionId,
      }));
      post("/api/QuestionPool/BatchDelete", {
        data: JSON.stringify(idList),
      }).then((res) => {
        this.loading = false;
        this.$message({
          type: "success",
          message: res.message,
        });

        this.delQuestion.forEach((element) => {
          this.questionList2 = this.questionList2.filter(
            (i) => i.questionId !== element.questionId
          );
        });
      });
    },
    importQuestion(list) {
      post("/api/QuesPoolAndExam/CheckData", {
        data: JSON.stringify(list),
      }).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "验证成功,开始导入",
          });
          list.forEach((item, index) => {
            this.importData(item, index, list.length);
          });
        } else {
          this.$message.error(resp.message);
        }
        this.$refs.fileBtn.value = null;
      });
      this.loading = true;
    },

    async importData(item, index, listlength) {
      this.loading = true;
      await post("/api/QuesPoolAndExam/BatchAdd", item)
        .then((resp) => {
          if (resp.code != 200) {
            this.$message({
              type: "error",
              message:
                ("1)第" + index + 1 + "行导入失败",
                "2)错误行数：" + item,
                "3)错误原因：" + resp.message),
            });
            return;
          }
          if (index + 1 == listlength) {
            this.togetArea(this.Company);
            this.$message({
              type: "success",
              message: "已全部导入",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("系统异常,请稍后再试");
        });
    },
    getCellWidth(value) {
      // 判断是否为null或undefined
      if (value == null) {
        return 10;
      } else if (/.*[\u4e00-\u9fa5]+.*$/.test(value)) {
        const chineseLength = value.match(/[\u4e00-\u9fa5]/g).length;
        const otherLength = value.length - chineseLength;
        return chineseLength * 2.1 + otherLength * 1.1;
      } else {
        return value.toString().length * 1.1;
      }
    },
    // 导出
    exportExcel() {
      return exportExcel(
        this.firstTable ? "#tableData3" : "#tableData2",
        "题库维护.xlsx"
      );
    },
    exportHeaderExcel() {
      this.questionList3 = [
        {
          compName: "公司名称",
          deptName: "部门名称",
          postName: "岗位名称",
          functionName: "职能名称",
          fileNo: "文件编号",
          fileName: "文件名称",
          question: "格式无限制",
          answer: "格式无限制",
          standardAbbr: "标准设置页面中（填写标准简称）",
          version: "格式无限制",
          riskPoints: "格式无限制",
          riskMeasures: "格式无限制",
          isCurrency: "√",
          isFuncCurrency: "√",
          isSpecial: "√",
          areaAudit: "√",
          fileAudit: "√",
          arrArea: "",
          arrAuditType: "",
        },
        {
          compName:
            "备注（所在区域与检查范畴填写对应的区域名称、范畴名称即可）",
          arrArea: "",
          arrAuditType: "",
        },
      ];
      if (this.questionList3.length != 0) {
        this.$nextTick(() => {
          exportExcel("#tabledData4", "题库维护.xlsx");
          this.questionList3 = [];
        });
      }
      // return exportExcel("#tabledData4", "题库维护.xlsx");
    },
    exportExcel2() {
      this.isUse = false;
      if (!this.isUse) {
        this.$nextTick(() => {
          exportExcel("#tabledData3", "题库维护.xlsx");
          this.isUse = true;
        });
      }
      // this.isUse = false;
      // this.$nextTick(() => {
      //   var wb = XLSX.utils.table_to_book(
      //     document.querySelector("#tabledData3")
      //   );
      //   let ray = [];
      //   let item = wb["Sheets"]["Sheet1"];
      //   for (const key in item) {
      //     if (item[key].t) {
      //       if (item[key].t == "s" && item[key].v !== "√") {
      //         let obj = {
      //           name: key.slice(0, 1),
      //           items: item[key],
      //         };
      //         ray.push(obj);
      //       }
      //     }
      //   }
      //   let rayZ = ray.splice(91, ray.length);
      //   let widthList = [
      //     { name: "A", length: 0 },
      //     { name: "B", length: 0 },
      //     { name: "C", length: 0 },
      //     { name: "D", length: 0 },
      //     { name: "E", length: 0 },
      //     { name: "F", length: 0 },
      //     { name: "G", length: 0 },
      //     { name: "H", length: 0 },
      //   ];
      //   for (let index = 0; index < rayZ.length; index++) {
      //     widthList.forEach((i) => {
      //       if (i.name === rayZ[index].name) {
      //         i.length =
      //           rayZ[index].items.v.length >= i.length
      //             ? rayZ[index].items.v.length
      //             : i.length;
      //       }
      //     });
      //   }
      //   widthList.forEach((e) => {
      //     wb["Sheets"]["Sheet1"]["!cols"].push({
      //       wpx: e.length * 15,
      //     });
      //   });
      //   var wbout = XLSX.write(wb, {
      //     bookType: "xlsx",
      //     bookSST: true,
      //     type: "array",
      //   });
      //   try {
      //     FileSaver.saveAs(
      //       new Blob([wbout], { type: "application/octet-stream" }),
      //       "题库维护.xlsx"
      //     );
      //   } catch (e) {
      //     if (typeof console !== "undefined") console.log(e, wbout);
      //   }
      //   this.isUse = true;
      //   return wbout;
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.tableBox {
  ::v-deep .el-table__row {
    cursor: pointer;
  }
}
/deep/ .dialog .el-form-item__label {
  padding: 0 !important;
}

.dialog .el-select {
  width: 100%;
}
.dialog .contaion > div:nth-of-type(1) {
  display: flex;
}
.dialog .contaion > div:nth-of-type(1) div {
  padding-right: 30px;
}
.select-header {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
}
/* 上传图片 */
/deep/ .el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
  line-height: 100px !important;
}
/deep/ .el-dialog__body {
  padding: 30px 20px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
  margin-right: 4px;
  margin-top: 4px;
}
/deep/.el-dialog__body {
  padding: 10px 20px !important;
}
._Table {
  overflow: auto;
}
.qustionBox {
  display: flex;
}
.total {
  position: absolute;
  right: 20px;
  padding-right: 20px;
  padding-top: 20px;
  font-weight: bold;
  cursor: pointer;
  > span {
    color: red;
    font-size: 13px;
  }
}
.toPage {
  display: flex;
  align-items: flex-end;
  background-color: #f2f2f2;
  padding: 5px 0;
  width: 100px;
  justify-content: center;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 5px;
  > img {
    width: 18px;
    height: 22px;
  }
  > span {
    color: #434343;
    border-bottom: 2px solid rgb(92, 92, 92);
    padding: 3px 5px;
    font-weight: bold;
  }
}
</style>
